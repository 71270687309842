import fetchWrapper from "./fetch_wrapper"
import { throwUnlessOk, handleError } from "./errors"
import { Consent, consented } from "./consent"

// We call indite for the affiliate link immediately unless
// the user has already declined consent for marketing
export const initializeAffiliateRecording = (location, referrer) => {
  if (consented(Consent.necessary)) {
    inditeAndMaybeRedirect(location, referrer)
  }
}

// Private //

const inditeAndMaybeRedirect = (location, referrer) => {
  fetchWrapper(inditePath(location, referrer))
    .then(throwUnlessOk)
    .then((response) => response.json())
    .then(({ redirect, redirect_url: redirectUrl }) => {
      if (redirect) location.href = redirectUrl
    })
    .catch(handleError)
}

const inditePath = (location, referrer) => {
  const searchParams = new URLSearchParams(location.search)

  searchParams.set("url", location.href)
  searchParams.set("referrer", referrer)

  return `/consociate/indite?${searchParams.toString()}`
}
